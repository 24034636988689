export enum DatadogEventType {
  // GENERAL/GLOBAL
  PAGE_VIEW = 'page_view',
  FAQ_BUTTON_CLICK = 'faq_button_click',
  PAGE_CHANGE_PREFIX = 'page_change-',

  // LOGIN
  LOGIN_SUBMIT = 'login_submit',
  RESET_PASSWORD_SUBMIT = 'reset_password_submit',
  FORGOT_PASSWORD_SUBMIT = 'forgot_password_submit',
  LOGOUT_SUBMIT = 'logout_submit',

  // MFA Authentication
  MFA_SETTING_UPDATE_FAILURE = 'mfa_authentication_failure',

  // API TOKENS
  API_TOKEN_GENERATE_SUBMIT = 'api_token_generate_submit',
  API_TOKEN_DEACTIVATE_CLICK = 'api_token_deactivate_click',
  API_TOKEN_DEACTIVATE_CONFIRM_CLICK = 'api_token_deactivate_confirm_click',
  API_TOKEN_STOP_DEACTIVATE_CLICK = 'api_token_stop_deactivate_click',
  API_TOKEN_REACTIVATE_CLICK = 'api_token_reactivate_click',
  API_TOKEN_NEW_CLICK = 'api_token_new_click',
  API_TOKEN_NEW_CONFIRM_CLICK = 'api_token_new_confirm_click',

  // REPORTS
  CREATE_REPORT_CLICK = 'create_report_click',
  DOWNLOAD_REPORTS_CLICK = 'download_reports_click',
  DELETE_REPORTS_CLICK = 'delete_reports_click',
  BULK_DELETE_REPORTS_CLICK = 'bulk_delete_reports_click',
  CREATE_KYX_REPORT = 'create_kyx_report',
  CREATE_V2_REPORT = 'create_v2_report',
  CREATE_V3_REPORT = 'create_v3_report',

  // USERS
  BULK_ACTIVATE_USERS_SUBMIT = 'bulk_activate_users_submit',
  ACTIVATE_USER_SUBMIT = 'activate_user_submit',
  BULK_DEACTIVATE_USERS_SUBMIT = 'bulk_deactivate_users_submit',
  DEACTIVATE_USER_SUBMIT = 'deactivate_user_submit',
  ADD_USER_CLICK = 'add_user_click',
  USER_DETAILS_CLICK = 'user_details_click',
  USER_UPDATE_SUBMIT = 'user_update_submit',
  USER_DETAILS = 'user_details',
  USER_RESEND_EMAIL = 'users_resend_email',
  USER_UNLOCK = 'users_unlock',
  USER_RESET_TFA = 'users_reset_tfa',
  USERS_BULK_CREATE = 'users_bulk_create',

  // MY PROFILE
  MY_PROFILE_PASSWORD_CHANGE_SUBMIT = 'my_profile_password_change_submit',

  // SETTINGS
  ACCEPTED_IDS_COUNTRY_SAVE_SUBMIT = 'accepted_ids_country_save_submit',
  ACCEPTED_IDS_REGION_SAVE_SUBMIT = 'accepted_ids_region_save_submit',
  ACCEPTED_IDS_REGION_COPY_SUBMIT = 'accepted_ids_region_copy_submit',
  APPLICATION_SETTINGS_GENERAL_SAVE_SUBMIT = 'application_settings_general_save_submit',
  APPLICATION_SETTINGS_REDIRECT_SAVE_SUBMIT = 'application_settings_redirect_save_submit',
  OPTIONAL_FIELDS_SAVE_SUBMIT = 'data_settings_optional_fields_save_submit',
  CUSTOMIZE_CLIENT_GENERAL_SAVE_SUBMIT = 'customize_client_general_save_submit',
  CUSTOMIZE_CLIENT_REDIRECT_SAVE_SUBMIT = 'customize_client_redirect_save_submit',
  DATA_SETTINGS_MANDATORY_FIELDS_SAVE_SUBMIT = 'data_settings_mandatory_fields_save_submit',
  DATA_SETTINGS_SCREENING_SAVE_SUBMIT = 'data_settings_screening_save_submit',
  DOCUMENT_VERIFICATION_CUSTOM_DOCUMENT_CREATE_CLICK = 'document_verification_custom_document_create_click',
  DOCUMENT_VERIFICATION_CUSTOM_DOCUMENT_UPDATE_CLICK = 'document_verification_custom_document_update_click',

  // VERIFICATION
  TRY_VERIFICATION_CLICK = 'try_verification_click',
  CREATE_VERIFICATION_CLICK = 'create_verification_click',
  VERIFICATION_DETAILS_CLICK = 'verification_details_click',
  DELETE_VERIFICATION_CLICK = 'delete_verification_click',
  DOWNLOAD_VERIFICATION_PDF_CLICK = 'download_verification_pdf_click',

  // SCREENING
  VERIFICATION_SCREENING_SEARCH_SUBMIT = 'verification_screening_search_submit',
  VERIFICATION_SCREENING_DETAILS_BOX_CLICK = 'verification_screening_details_box_click',
  VERIFICATION_SCREENING_MONITORING_CHANGE_SUBMIT = 'verification_screening_monitoring_change_submit',
  VERIFICATION_SCREENING_MATCH_STATUS_CHANGE_SUBMIT = 'verification_screening_match_status_change_submit',

  // WORKFLOWS
  WORKFLOW_DETAILS_CLICK = 'workflow_details_click',
  WORKFLOW_DETAILS_OPEN_CAPABILITY_URL = 'workflow_details-open_capability_url',
  WORKFLOW_DETAILS_COPY_UUID = 'workflow_details-copy_uuid',
  WORKFLOW_DETAILS_IMAGE_MODAL = 'workflow_details-open_image_modal',
  WORKFLOW_ACCOUNT_DETAILS_CLICK = 'workflow_account_details_click',
  WORKFLOW_TRANSACTION_CLICK = 'try_workflow_transaction_click',
  WORKFLOW_CREATE_TRANSACTION_CLICK = 'create_workflow_transaction_click',
  DOWNLOAD_WORKFLOW_PDF_CLICK = 'download_workflow_pdf_click',

  // TRANSACTIONS

  TRANSACTION_COPY_TO_CLIPBOARD = 'transactions-copy_to_clipboard',
  TRANSACTION_DETAILS_ONPEN_COMPLY_ADVANTAGE = 'transaction_details-open_comply_advantage_link',
  TRANSACTION_DETAILS_OPEN_IMAGE_MODAL = 'transaction_details-open_image_modal',
  TRANSACTION_LIST_GO_TO_DETAIL_PAGE = 'transaction_list-go_to_detail_page',
  TRANSACTION_LIST_TABLE_SORT = 'transaction_list-table_sort',
  TRANSACTION_LIST_SORT_FILTER = 'transaction_list-sort_filter',
  TRANSACTION_LIST_STATUS_FILTER = 'transaction_list-status_filter',
  TRANSACTION_LIST_ADVANCED_FILTER = 'transaction_list-advanced_filter',
  TRANSACTION_LIST_DATE_FILTER = 'transaction_list-date_filter',
  TRANSACTION_OPEN_DATEPICKER = 'transaction_list-open-datepicker',
  TRY_TRANSACTION_CLICK = 'try_transaction_click',
  CREATE_TRANSACTION_CLICK = 'create_transaction_click'
}
