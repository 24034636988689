import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService, EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {TenantSettingsHierarchy, TenantSettingsUpdate} from 'shared/interfaces/tenant-settings';
import {TenantSettingsEndpoints} from 'shared/services/endpoint-constants';

@Injectable()
export class TenantSettingsService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = TenantSettingsEndpoints.BASE;
  }

  public getGlobalSettingsHierarchy$(id: string): Observable<TenantSettingsHierarchy> {
    return this.get$<TenantSettingsHierarchy>(TenantSettingsEndpoints.GLOBAL_HIERARCHY(id));
  }

  public saveHierarchyValues$(payload: TenantSettingsUpdate, password: string): Observable<void> {
    return this.post$(TenantSettingsEndpoints.GLOBAL_HIERARCHY_SAVE, new EntityWithPassword(payload, password));
  }
}
