export enum Permission {
  /**
   * Permission to access netverify API
   */
  ACCESS_NETVERIFY_API = 'PERM_ACCESS_NETVERIFY_API',

  /**
   * Permission to access Netverify General API (all API excluding Retrieval API)
   */
  ACCESS_NETVERIFY_GENERAL_API = 'PERM_ACCESS_NETVERIFY_GENERAL_API',

  /**
   * Permission to access netverify retrieval API
   */
  ACCESS_NETVERIFY_RETRIEVAL_API = 'PERM_ACCESS_NETVERIFY_RETRIEVAL_API',

  /**
   * Permission to access MDX acquisition facade API
   */
  ACCESS_MDX_ACQUISITION_FACADE_API = 'PERM_ACCESS_MDX_ACQUISITION_FACADE_API',

  /**
   * Permission to access MDX document facade API
   */
  ACCESS_MDX_DOCUMENT_FACADE_API = 'PERM_ACCESS_MDX_DOCUMENT_FACADE_API',

  /**
   * Permission to access recognition API (scanning client)
   */
  ACCESS_RECOGNITION_API = 'PERM_ACCESS_RECOGNITION_API',

  /**
   * Permission to access internal admin API
   */
  ACCESS_ADMIN_API = 'PERM_ACCESS_ADMIN_API',

  /**
   * Permission to access internal BO API
   */
  ACCESS_INTERNAL_API = 'PERM_ACCESS_INTERNAL_API',

  ACCESS_PCI_RESOURCES = 'PERM_ACCESS_PCI_RESOURCES',

  /**
   * User permissions
   */
  VIEW_USER_OWN_NAME = 'PERM_VIEW_USER_OWN_NAME',

  EDIT_USER_OWN_NAME = 'PERM_EDIT_USER_OWN_NAME',

  VIEW_USER_OWN_EMAIL = 'PERM_VIEW_USER_OWN_EMAIL',

  EDIT_USER_OWN_EMAIL = 'PERM_EDIT_USER_OWN_EMAIL',

  CHANGE_USER_OWN_PASSWORD = 'PERM_CHANGE_USER_OWN_PASSWORD',

  USER_OWN_BULK_DOWNLOAD = 'PERM_BULK_DOWNLOAD_OWN_USER',

  /**
   * Merchants
   */
  FILTER_MERCHANTS = 'PERM_FILTER_MERCHANTS',

  ENABLE_MERCHANT = 'PERM_ENABLE_MERCHANT',

  DISABLE_MERCHANT = 'PERM_DISABLE_MERCHANT',

  /**
   * Netverify merchant
   */
  VIEW_NETVERIFY_MERCHANTS = 'PERM_VIEW_NETVERIFY_MERCHANTS',

  REGISTER_NETVERIFY_MERCHANT = 'PERM_REGISTER_NETVERIFY_MERCHANT',

  ENABLE_NETVERIFY_MERCHANT = 'PERM_ENABLE_NETVERIFY_MERCHANT',

  DISABLE_NETVERIFY_MERCHANT = 'PERM_DISABLE_NETVERIFY_MERCHANT',

  VIEW_NETVERIFY_MERCHANT_APPLICATION_SETTINGS = 'PERM_VIEW_NETVERIFY_MERCHANT_APPLICATION_SETTINGS',

  EDIT_NETVERIFY_MERCHANT_APPLICATION_SETTINGS = 'PERM_EDIT_NETVERIFY_MERCHANT_APPLICATION_SETTINGS',

  VIEW_NETVERIFY_MERCHANT_CLIENT_CUSTOMIZATION = 'PERM_VIEW_NETVERIFY_MERCHANT_CLIENT_CUSTOMIZATION',

  EDIT_NETVERIFY_MERCHANT_CLIENT_CUSTOMIZATION = 'PERM_EDIT_NETVERIFY_MERCHANT_CLIENT_CUSTOMIZATION',

  VIEW_NETVERIFY_MERCHANT_SLA_SETTINGS = 'PERM_VIEW_NETVERIFY_MERCHANT_SLA_SETTINGS',

  EDIT_NETVERIFY_MERCHANT_SLA_SETTINGS = 'PERM_EDIT_NETVERIFY_MERCHANT_SLA_SETTINGS',

  VIEW_NETVERIFY_MERCHANT_SETTINGS = 'PERM_VIEW_NETVERIFY_MERCHANT_SETTINGS',

  EDIT_NETVERIFY_MERCHANT_SETTINGS = 'PERM_EDIT_NETVERIFY_MERCHANT_SETTINGS',

  VIEW_NETVERIFY_MERCHANT_DETAILS = 'PERM_VIEW_NETVERIFY_MERCHANT_DETAILS',

  EDIT_NETVERIFY_MERCHANT_DETAILS = 'PERM_EDIT_NETVERIFY_MERCHANT_DETAILS',

  VIEW_NETVERIFY_MERCHANT_ACCEPTED_IDS = 'PERM_VIEW_NETVERIFY_MERCHANT_ACCEPTED_IDS',

  VIEW_NETVERIFY_MERCHANT_ACCEPTED_VISA = 'PERM_VIEW_NETVERIFY_MERCHANT_ACCEPTED_VISA',

  EDIT_NETVERIFY_MERCHANT_ACCEPTED_IDS = 'PERM_EDIT_NETVERIFY_MERCHANT_ACCEPTED_IDS',

  VIEW_NETVERIFY_MERCHANT_MANDATORY_FIELDS = 'PERM_VIEW_NETVERIFY_MERCHANT_MANDATORY_FIELDS',

  EDIT_NETVERIFY_MERCHANT_MANDATORY_FIELDS = 'PERM_EDIT_NETVERIFY_MERCHANT_MANDATORY_FIELDS',

  VIEW_NETVERIFY_MERCHANT_API_CREDENTIALS = 'PERM_VIEW_NETVERIFY_MERCHANT_API_CREDENTIALS',

  REGENERATE_NETVERIFY_MERCHANT_API_SECRET = 'PERM_REGENERATE_NETVERIFY_MERCHANT_API_SECRET',

  VIEW_NETVERIFY_MERCHANT_SCREENING_FIELDS = 'PERM_VIEW_NETVERIFY_MERCHANT_SCREENING_FIELDS',

  EDIT_NETVERIFY_MERCHANT_SCREENING_FIELDS = 'PERM_EDIT_NETVERIFY_MERCHANT_SCREENING_FIELDS',

  /**
   * Netverify resource: document types
   */

  /**
   * Netverify resource: document types
   */
  VIEW_DOCUMENT_TYPES = 'PERM_VIEW_DOCUMENT_TYPES',

  /**
   * Netverify resource: custom document types
   */
  VIEW_CUSTOM_DOCUMENT_TYPES = 'PERM_VIEW_CUSTOM_DOCUMENT_TYPES',

  VIEW_CUSTOM_DOCUMENT_TYPE = 'PERM_VIEW_CUSTOM_DOCUMENT_TYPE',

  MANAGE_CUSTOM_DOCUMENT_TYPE = 'PERM_MANAGE_CUSTOM_DOCUMENT_TYPE',

  /**
   * Netverify resource: sample/fraud/address images
   */
  VIEW_NETVERIFY_ID_SAMPLES = 'PERM_VIEW_NETVERIFY_ID_SAMPLES',

  EDIT_NETVERIFY_ID_SAMPLE = 'PERM_EDIT_NETVERIFY_ID_SAMPLE',

  DELETE_NETVERIFY_ID_SAMPLE = 'PERM_DELETE_NETVERIFY_ID_SAMPLE',

  /**
   * Additional reject information
   */
  VIEW_ADDITIONAL_REJECT_INFORMATION = 'PERM_VIEW_ADDITIONAL_REJECT_INFORMATION',
  VIEW_NETVERIFY_SCAN_REJECT_REASON = 'PERM_VIEW_NETVERIFY_SCAN_REJECT_REASON',

  /**
   * Viewing Sandbox Version
   */
  VIEW_TAGGING_INFRA_SANDBOX_VERSION = 'PERM_VIEW_TAGGING_INFRA_SANDBOX_VERSION',

  VIEW_TAGGING_INFRA_LEGACY_VERSION = 'PERM_VIEW_TAGGING_INFRA_LEGACY_VERSION',

  /**
   * Netverify resources: IDScan
   */
  VIEW_NETVERIFY_SCANS = 'PERM_VIEW_NETVERIFY_SCANS',

  VIEW_NETVERIFY_SCAN = 'PERM_VIEW_NETVERIFY_SCAN',

  VIEW_NETVERIFY_SCAN_AUDIT_ENTRIES = 'PERM_VIEW_NETVERIFY_SCAN_AUDIT_ENTRIES',

  VIEW_NETVERIFY_SCAN_INTERNAL_INFORMATION = 'PERM_VIEW_NETVERIFY_SCAN_INTERNAL_INFORMATION',

  VIEW_NETVERIFY_SCAN_DOCUMENT_DATA = 'PERM_VIEW_NETVERIFY_SCAN_DOCUMENT_DATA',

  VIEW_NETVERIFY_SCAN_CUSTOMER_DATA = 'PERM_VIEW_NETVERIFY_SCAN_CUSTOMER_DATA',

  VIEW_NETVERIFY_SCAN_AUTO_EXTRACTED_DATA = 'PERM_VIEW_NETVERIFY_SCAN_AUTO_EXTRACTED_DATA',

  VIEW_NETVERIFY_SCAN_SDK_INFORMATION = 'PERM_VIEW_NETVERIFY_SCAN_SDK_INFORMATION',

  VIEW_NETVERIFY_OWNED_SCANS = 'PERM_VIEW_NETVERIFY_OWNED_SCANS',

  VIEW_NETVERIFY_OWNED_SCAN = 'PERM_VIEW_NETVERIFY_OWNED_SCAN',

  VIEW_NETVERIFY_OWNED_SCANS_FULL = 'PERM_VIEW_NETVERIFY_OWNED_SCANS_FULL',

  FILTER_NETVERIFY_SCANS = 'PERM_FILTER_NETVERIFY_SCANS',

  EXPORT_NETVERIFY_SCANS = 'PERM_EXPORT_NETVERIFY_SCANS',

  REQUEST_NETVERIFY_SCAN_MASKING = 'PERM_REQUEST_NETVERIFY_SCAN_MASKING',

  INIT_NETVERIFY_SCAN = 'PERM_INIT_NETVERIFY_SCAN',

  VIEW_NETVERIFY_VERIFICATIONS = 'PERM_VIEW_VERIFICATIONS', // If customer has verifications enabled

  /**
   * Workflow Permissions
   */
  VIEW_WORKFLOWS = 'PERM_VIEW_WORKFLOWS', // If customer has workflows enabled
  VIEW_WORKFLOW_DETAIL = 'PERM_VIEW_WORKFLOW_DETAIL',
  VIEW_WORKFLOW_LIST = 'PERM_VIEW_WORKFLOW_LIST',
  WIPEOUT_WORKFLOW = 'PERM_WIPEOUT_WORKFLOW_AS_OWNER',
  ACCESS_V3_TRY_PAGES = 'PERM_ACCESS_V3_TRY_PAGES',

  /**
   * Transaction Permissions
   */
  VIEW_TRANSACTION_GUIDE = 'PERM_VIEW_KYX', // If customer has transactions enabled
  VIEW_TRANSACTION_DETAIL = 'PERM_VIEW_KYX_DETAIL',
  VIEW_TRANSACTION_LIST = 'PERM_VIEW_KYX_LIST',
  WIPEOUT_TRANSACTION = 'PERM_WIPEOUT_KYX_TRANSACTION_AS_OWNER',
  ACCESS_KYX_TRY_PAGES = 'PERM_ACCESS_KYX_TRYNOW_PAGES',

  /**
   * AP Workflow and Transaction Permissions
   */
  AP_WIPEOUT_WORKFLOW = 'PERM_WIPEOUT_WORKFLOW',

  /**
   * View image excluding images with sensitive data (eg. card number, ....)
   */
  VIEW_NETVERIFY_INSENSITIVE_IMAGE = 'PERM_VIEW_NETVERIFY_INSENSITIVE_IMAGE',

  /**
   * View all images (including images with sensitive data)
   */
  VIEW_NETVERIFY_IMAGE = 'PERM_VIEW_NETVERIFY_IMAGE',

  /**
   * View all images temporary just for an operation
   */
  VIEW_NETVERIFY_IMAGE_TEMPORARY = 'PERM_VIEW_NETVERIFY_IMAGE_TEMPORARY',

  /**
   * View images (excluding images with sensitive data)
   */
  VIEW_NETVERIFY_INSENSITIVE_IMAGE_AS_OWNER = 'PERM_VIEW_NETVERIFY_INSENSITIVE_IMAGE_AS_OWNER',

  /**
   * eg. Backoffice may view image while working on it. When scan is in final state image access is forbidden.
   */
  VIEW_NETVERIFY_IMAGE_WHILE_IN_PROGRESS = 'PERM_VIEW_NETVERIFY_IMAGE_WHILE_IN_PROGRESS',

  /**
   * View all images when owner.
   */
  VIEW_NETVERIFY_IMAGE_AS_OWNER = 'PERM_VIEW_NETVERIFY_IMAGE_AS_OWNER',

  /**
   * View netverify unmasked owned image when merchant is PCI compliant
   */
  VIEW_NETVERIFY_UNMASKED_OWNED_IMAGE_WHEN_PCI_COMPLIANT = 'PERM_VIEW_NETVERIFY_UNMASKED_OWNED_IMAGE_WHEN_PCI_COMPLIANT',

  /**
   * Wipe out personal data of a scan as owner
   */
  WIPE_OUT_NETVERIFY_PERSONAL_DATA_AS_OWNER = 'PERM_WIPE_OUT_NETVERIFY_PERSONAL_DATA_AS_OWNER',

  /**
   * Wipe out personal data of a scan
   */
  WIPE_OUT_NETVERIFY_PERSONAL_DATA = 'PERM_WIPE_OUT_NETVERIFY_PERSONAL_DATA',

  /**
   * Set Scan as usable/unusable on image hash
   */
  EDIT_IMAGE_HASH = 'PERM_EDIT_IMAGE_HASH',

  /**
   * Process owned Netverify scans via API
   */
  PROCESS_API_NETVERIFY_OWNED_SCAN = 'PERM_PROCESS_API_NETVERIFY_OWNED_SCAN',

  /**
   * Country ID settings
   */
  VIEW_COUNTRIES_ID_SETTINGS = 'PERM_VIEW_COUNTRIES_ID_SETTINGS',

  VIEW_COUNTRY_ID_SETTINGS = 'PERM_VIEW_COUNTRY_ID_SETTINGS',

  EDIT_COUNTRY_ID_SETTINGS = 'PERM_EDIT_COUNTRY_ID_SETTINGS',

  ADD_COUNTRY_ID_SETTINGS = 'PERM_ADD_COUNTRY_ID_SETTINGS',

  /**
   * SLA/Fraud check settings
   */
  VIEW_NETVERIFY_SLA_DEFAULT_SETTINGS = 'PERM_VIEW_NETVERIFY_SLA_DEFAULT_SETTINGS',

  EDIT_NETVERIFY_SLA_DEFAULT_SETTINGS = 'PERM_EDIT_NETVERIFY_SLA_DEFAULT_SETTINGS',

  /**
   * Batch Upload Credentials Settings
   */

  VIEW_BATCH_UPLOAD = 'VIEW_BATCH_UPLOAD',

  /**
   *
   * Users
   *
   */

  /**
   * View all users
   */
  VIEW_USERS = 'PERM_VIEW_USERS',

  /**
   * Netverify report roles
   */
  ACCESS_NETVERIFY_REPORTS = 'PERM_ACCESS_NETVERIFY_REPORTS',

  CREATE_NETVERIFY_REPORTS = 'PERM_CREATE_NETVERIFY_REPORTS',

  DELETE_NETVERIFY_REPORTS = 'PERM_DELETE_NETVERIFY_REPORTS',

  DOWNLOAD_NETVERIFY_REPORTS = 'PERM_DOWNLOAD_NETVERIFY_REPORTS',

  /**
   * View all Netverify users (jumio and merchant users for Netverify product)
   */
  VIEW_NETVERIFY_USERS = 'PERM_VIEW_NETVERIFY_USERS',

  /**
   * View all MobileSwipe users (jumio and merchant users for MobileSwipe product)
   */
  VIEW_USER = 'PERM_VIEW_USER',

  FILTER_USERS = 'PERM_FILTER_USERS',

  MANAGE_JUMIO_USER_DATA = 'PERM_MANAGE_JUMIO_USER_DATA',

  MANAGE_JUMIO_USER_ROLE = 'PERM_MANAGE_JUMIO_USER_ROLE',

  RESEND_JUMIO_USER_VERIFICATION_LINK = 'PERM_RESEND_JUMIO_USER_VERIFICATION_LINK',

  ACTIVATE_JUMIO_USER = 'PERM_ACTIVATE_JUMIO_USER',

  DEACTIVATE_JUMIO_USER = 'PERM_DEACTIVATE_JUMIO_USER',

  RESET_TFA_SECRET_CODE_JUMIO_USER = 'PERM_RESET_TFA_SECRET_CODE_JUMIO_USER',

  EXPIRE_SESSIONS_JUMIO_USER = 'PERM_EXPIRE_SESSIONS_JUMIO_USER',

  REGISTER_JUMIO_USER = 'PERM_REGISTER_JUMIO_USER',

  /**
   * Merchant users
   */
  VIEW_MERCHANT_USERS = 'PERM_VIEW_MERCHANT_USERS',

  VIEW_MERCHANT_USER = 'PERM_VIEW_MERCHANT_USER',

  FILTER_MERCHANT_USERS = 'PERM_FILTER_MERCHANT_USERS',

  MANAGE_MERCHANT_USER_DATA = 'PERM_MANAGE_MERCHANT_USER_DATA',

  MANAGE_MERCHANT_OWN_USER_DATA = 'PERM_MANAGE_MERCHANT_OWN_USER_DATA',

  MANAGE_MERCHANT_OWN_USER_ROLE = 'PERM_MANAGE_MERCHANT_OWN_USER_ROLE',

  MANAGE_MERCHANT_USER_ROLE = 'PERM_MANAGE_MERCHANT_USER_ROLE',

  RESEND_MERCHANT_USER_VERIFICATION_LINK = 'PERM_RESEND_MERCHANT_USER_VERIFICATION_LINK',

  RESEND_MERCHANT_USER_VERIFICATION_OWN_LINK = 'PERM_RESEND_MERCHANT_OWN_USER_VERIFICATION_LINK',

  ACTIVATE_MERCHANT_USER = 'PERM_ACTIVATE_MERCHANT_USER',

  ACTIVATE_MERCHANT_OWN_USER = 'PERM_ACTIVATE_MERCHANT_OWN_USER',

  DEACTIVATE_MERCHANT_OWN_USER = 'PERM_DEACTIVATE_MERCHANT_OWN_USER',

  DEACTIVATE_MERCHANT_USER = 'PERM_DEACTIVATE_MERCHANT_USER',

  RESET_TFA_SECRET_CODE_MERCHANT_USER = 'PERM_RESET_TFA_SECRET_CODE_MERCHANT_USER',

  RESET_TFA_SECRET_CODE_MERCHANT_OWN_USER = 'PERM_RESET_TFA_SECRET_CODE_MERCHANT_OWN_USER',

  EXPIRE_SESSIONS_MERCHANT_USER = 'PERM_EXPIRE_SESSIONS_MERCHANT_USER',

  REGISTER_MERCHANT_USER = 'PERM_REGISTER_MERCHANT_USER',

  /**
   * Mail templates
   */
  VIEW_MAIL_TEMPLATES = 'PERM_VIEW_MAIL_TEMPLATES',

  FILTER_MAIL_TEMPLATES = 'PERM_FILTER_MAIL_TEMPLATES',

  VIEW_MAIL_TEMPLATE = 'PERM_VIEW_MAIL_TEMPLATE',

  EDIT_MAIL_TEMPLATE = 'PERM_EDIT_MAIL_TEMPLATE',

  /**
   * UI permissions
   */

  ACCESS_NETVERIFY_UI = 'PERM_ACCESS_NETVERIFY_UI',

  ACCESS_ADMIN_UI = 'PERM_ACCESS_ADMIN_UI',

  ACCESS_BACKOFFICE_UI = 'PERM_ACCESS_BACKOFFICE_UI',

  ACCESS_OCR_UI = 'PERM_ACCESS_OCR_UI',

  ACCESS_NETVERIFY_TRY_PAGES = 'PERM_ACCESS_NETVERIFY_TRY_PAGES',

  /**
   * Fraud DB permissions
   */
  VIEW_FRAUD_DB = 'PERM_VIEW_FRAUD_DB',

  MANAGE_FRAUD_DB = 'PERM_MANAGE_FRAUD_DB',

  VIEW_FRAUD_ENTRY = 'PERM_VIEW_FRAUD_ENTRY',

  EDIT_FRAUD_ENTRY = 'PERM_EDIT_FRAUD_ENTRY',

  /**
   * Dead letter queue permissions
   */
  VIEW_DEAD_LETTER_QUEUE = 'PERM_VIEW_DEAD_LETTER_QUEUE',

  /**
   * Permission to reset the authorization tokens of agents for Jumbo
   */
  VIEW_AGENTS = 'PERM_VIEW_AGENTS',

  RESET_TOKEN_AGENT = 'PERM_RESET_TOKEN_AGENT',

  /**
   * Permissions introduced with agent service
   */
  ACCESS_RESTQ_API = 'PERM_ACCESS_RESTQ_API',
  VIEW_AGENT = 'PERM_VIEW_AGENT',
  CHANGE_AGENT = 'PERM_CHANGE_AGENT',
  SCANS = 'PERM_SCANS',

  /**
   * Permission to the limited search on the verifications page
   */
  LIMITED_SEARCH = 'PERM_LIMITED_SEARCH',

  /**
   * Permission to access jumbo remoting by various BO users
   */
  PERM_ACCESS_INTERNAL_API = 'PERM_PERM_ACCESS_INTERNAL_API',

  /**
   * Experiment UI permissions
   */
  EXPERIMENT_UI_ADMIN = 'PERM_EXPERIMENT_UI_ADMIN', // This is a MACHINE_LEARNING_ADMIN role Permission
  EXPERIMENT_UI_ENGINEER = 'PERM_EXPERIMENT_UI_ENGINEER', // This is a MACHINE_LEARNING_ENGINEER role Permission

  // For NVMA users in Customer Portal
  MERCHANT_NETVERIFY_ADMIN = 'MERCHANT_NETVERIFY_ADMIN',

  ADD_MERCHANT_USER = 'PERM_ADD_MERCHANT_USER',

  // User roles
  ADMIN_NETVERIFY_INTEGRATION = 'ADMIN_NETVERIFY_INTEGRATION',
  ADMIN_NETVERIFY_PRODUCTMANAGER = 'ROLE_ADMIN_NETVERIFY_PRODUCTMANAGER',
  ROLE_ADMIN_NETVERIFY_INTEGRATION = 'ROLE_ADMIN_NETVERIFY_INTEGRATION',
  ROLE_BACKOFFICE_NETVERIFY_MANAGER = 'ROLE_BACKOFFICE_NETVERIFY_MANAGER',

  // Remediation
  VIEW_NETVERIFY_REMEDIATION_SETTINGS = 'PERM_VIEW_NETVERIFY_REMEDIATION_SETTINGS',
  EDIT_NETVERIFY_REMEDIATION_SETTINGS = 'PERM_EDIT_NETVERIFY_REMEDIATION_SETTINGS',

  // Mobile SDK
  VIEW_NETVERIFY_MOBILE_SETTINGS = 'PERM_VIEW_NETVERIFY_MOBILE_SETTINGS',
  EDIT_NETVERIFY_MOBILE_SETTINGS = 'PERM_EDIT_NETVERIFY_MOBILE_SETTINGS',

  // Automation facade debug log
  VIEW_AUTOMATION_FACADE_DEBUG_LOG = 'PERM_VIEW_AUTOMATION_FACADE_DEBUG_LOG',

  // Risk Management
  ACCESS_RISK_MANAGEMENT = 'PERM_ACCESS_RISK_MANAGEMENT',

  // Screening
  ACCESS_SCREENING = 'PERM_ACCESS_SCREENING',

  // Custom Queues
  ACCESS_CUSTOM_QUEUES = 'PERM_ACCESS_CUSTOM_QUEUES',

  // Consent Settings
  VIEW_GLOBAL_CONSENT = 'PERM_VIEW_GLOBAL_CONSENT',
  EDIT_GLOBAL_CONSENT = 'PERM_EDIT_GLOBAL_CONSENT',

  VIEW_CUSTOMER_CONSENT = 'PERM_VIEW_CUSTOMER_CONSENT',
  EDIT_CUSTOMER_CONSENT = 'PERM_EDIT_CUSTOMER_CONSENT',

  // Audit Log Setttings
  VIEW_AUDIT_LOG = 'PERM_VIEW_AUDIT_LOG',
  DOWNLOAD_AUDIT_LOG = 'PERM_DOWNLOAD_AUDIT_LOG',

  // IP Whitelist
  EDIT_IP_WHITELIST = 'PERM_EDIT_MERCHANT_IP_WHITELIST',

  VIEW_NETVERIFY_BANNER_DEFAULT_SETTINGS = 'PERM_VIEW_NETVERIFY_BANNER_DEFAULT_SETTINGS',
  EDIT_NETVERIFY_BANNER_DEFAULT_SETTINGS = 'PERM_EDIT_NETVERIFY_BANNER_DEFAULT_SETTINGS',

  /**
   * KYX report roles
   */
  ACCESS_KYX_REPORTS = 'PERM_ACCESS_KYX_REPORTS',
  CREATE_KYX_REPORTS = 'PERM_CREATE_KYX_REPORTS',
  DELETE_KYX_REPORTS = 'PERM_DELETE_KYX_REPORTS',
  DOWNLOAD_KYX_REPORTS = 'PERM_DOWNLOAD_KYX_REPORTS',

  /**
   * V3 Workflows report roles
   */
  ACCESS_V3_REPORTS = 'PERM_ACCESS_WORKFLOW_REPORTS',
  CREATE_V3_REPORTS = 'PERM_CREATE_WORKFLOW_REPORTS',
  DELETE_V3_REPORTS = 'PERM_DELETE_WORKFLOW_REPORTS',
  DOWNLOAD_V3_REPORTS = 'PERM_DOWNLOAD_WORKFLOW_REPORTS',

  // Lightning
  VIEW_MERCHANT_INTEGRATION_SETTINGS = 'PERM_VIEW_NETVERIFY_MERCHANT_SETTINGS',
  EDIT_MERCHANT_INTEGRATION_SETTINGS = 'PERM_EDIT_NETVERIFY_MERCHANT_SETTINGS',

  // Orchestration
  EDIT_WORKFLOW_MANAGER = 'PERM_EDIT_WORKFLOW_MANAGER',

  // JUMIO 4.0
  VIEW_J4_CUSTOMERS = 'PERM_VIEW_J4_CUSTOMERS',
  CREATE_J4_CUSTOMER = 'PERM_REGISTER_J4_CUSTOMER',
  VIEW_J4_TENANTS = 'PERM_VIEW_J4_TENANTS',
  CREATE_J4_TENANT = 'PERM_REGISTER_J4_TENANT',
  EDIT_J4_USERS = 'PERM_EDIT_J4_USERS',
  VIEW_J4_USERS = 'PERM_VIEW_J4_USERS',
  CREATE_J4_USER = 'PERM_REGISTER_J4_USER',

  REGISTER_NETVERIFY_CUSTOMER = 'PERM_REGISTER_NETVERIFY_MERCHANT',
  REGISTER_NETVERIFY_TENANT = 'PERM_REGISTER_NETVERIFY_MERCHANT',

  // SSO user role
  PERM_ROLE_MERCHANT_NETVERIFY_ADMIN = 'ROLE_MERCHANT_NETVERIFY_ADMIN',

  /*
   * J4 IDP Settings AND CP
   */

  VIEW_NETVERIFY_MERCHANT_IDP_SETTINGS = 'PERM_VIEW_NETVERIFY_MERCHANT_IDP_SETTINGS',
  EDIT_NETVERIFY_MERCHANT_IDP_SETTINGS = 'PERM_EDIT_NETVERIFY_MERCHANT_IDP_SETTINGS',
  /*
   * J4 Dashboard related
   */
  PERM_VIEW_KYC_DASHBOARD = 'PERM_VIEW_KYC_DASHBOARD',
  PERM_VIEW_JTM_DASHBOARD = 'PERM_VIEW_JTM_DASHBOARD',
  /*
   * DSR requests
   */
  CREATE_DSR_REQUEST = 'PERM_CREATE_DSR_REQUEST',
  DOWNLOAD_DSR_RESULTS = 'PERM_DOWNLOAD_DSR_RESULTS',
  VIEW_DSR_REQUESTS = 'PERM_VIEW_DSR_REQUESTS',
  /*
   * Replay scans
   */
  REPLAY_SCAN = 'PERM_REPLAY_SCAN'
}
