<app-modal title="Re-authentication" (onClose)="onCancelClicked($event)" #modal>
  Extra verification is needed to save changes on this page. You will be re-directed to another window to complete the process.
  <div class="modal-footer">
    <button
      data-cy="modal-mfa-confirmation-confirm-btn"
      type="button"
      class="btn btn-primary button button-primary pull-right"
      appDebounceClick
      aria-label="Confirm"
      (click)="onConfirmClicked($event)"
    >
      Continue
    </button>
    <button
      data-cy="modal-mfa-confirmation-cancel-btn"
      type="button"
      class="button btn btn-cancel pull-right"
      aria-label="Close"
      (click)="onCancelClicked($event)"
    >
      Cancel
    </button>
  </div>
</app-modal>
