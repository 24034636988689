import {CodeLabelIcon} from 'public-shared/models/code-label/code-label.dto';
import {Country} from 'shared/services/country/country.dto';
import {IconConstants} from 'shared/services/icon.constants';
import {humanize} from 'shared/services/text.helper';
import {
  CredentialCategory,
  WorkflowCredentialSummary,
  WorkflowDecisionSummary
} from 'shared/services/workflow-executions/workflow-executions.interfaces';
import {WorkflowStatus} from './workflow-executions.interfaces';

/**
 * Mapped from Workflow to represent the UI struct
 */
export class WorkflowDatatableElement {
  public static EXTRACTION_KEY_COUNTRY = 'issuingCountry';
  public static EXTRACTION_KEY_TYPE = 'idType';
  public static EXTRACTION_KEY_FIRST_NAME = 'firstName';
  public static EXTRACTION_KEY_LAST_NAME = 'lastName';

  public workflowId: string;
  public workflowDefinitionKey: string;
  public accountId: string;
  public date: string;
  public credentials: WorkflowDatatableCredential[];
  public idCredential: WorkflowDatatableCredential | undefined;
  public wipedOutAt: string;
  public workflowStatus: WorkflowStatus;
  public completedAt: string;
  public channel: string | undefined = '';

  // AP Only
  public customerId?: string;
  public customerName?: string;

  // KYX Only
  public riskScore?: string;
  public transactionStatus?: string;
  public workflowName?: string;
  public customerReference?: string;

  public documentNumber?: string;

  constructor(workflow: any) {
    this.accountId = workflow.accountId;
    this.credentials = this.buildCredentials(workflow);
    this.idCredential = this.credentials.find((credential: WorkflowDatatableCredential) => credential.credential === 'id');
    this.channel = this.credentials.find((credential: WorkflowDatatableCredential) => credential.channel)?.channel;
    this.date = this.getDate(workflow);
    this.workflowId = workflow.workflowExecutionId;
    this.workflowDefinitionKey = workflow.workflowDefinitionKey;
    this.customerId = workflow.merchant?.id;
    this.customerName = workflow.merchant?.name;
    this.wipedOutAt = workflow.wipedOutAt;
    this.workflowStatus = workflow.workflowStatus;
    this.completedAt = workflow.completedAt;

    this.riskScore = workflow?.riskScore;
    this.transactionStatus = workflow?.transactionStatus;
    this.workflowName = workflow.workflow;
    this.customerReference = workflow.customerReference;

    this.documentNumber = workflow.documentNumber;
  }

  public getDate(workflow: any): string {
    if (workflow.completedAt) {
      return workflow.completedAt;
    }
    if (workflow.startedAt) {
      return workflow.startedAt;
    }
    return workflow.initiatedAt;
  }

  public buildCredentials(workflow: any): WorkflowDatatableCredential[] {
    const credentials = [] as WorkflowDatatableCredential[];
    for (const credentialSummary of workflow.credentialSummaries) {
      if (credentialSummary.category && credentialSummary.decisionSummary) {
        if (credentialSummary.category === CredentialCategory.ID) {
          credentials.push(this.getExtractionData(credentialSummary));
        } else {
          credentials.push(this.getDefaultCredentialData(credentialSummary));
        }
      }
    }
    return credentials;
  }

  public getDefaultCredentialData(credentialSummary: WorkflowCredentialSummary): WorkflowDatatableCredential {
    const {category, decisionSummary, channel} = credentialSummary;
    return {
      credential: category.toLowerCase(),
      capability: decisionSummary.capability,
      result: humanize(decisionSummary.type),
      channel
    };
  }

  public getExtractionData(credentialSummary: WorkflowCredentialSummary): WorkflowDatatableCredential {
    const decisionSummary: WorkflowDecisionSummary = credentialSummary.decisionSummary;
    const credential = this.getDefaultCredentialData(credentialSummary);
    for (const data of decisionSummary.data) {
      switch (data.label) {
        case WorkflowDatatableElement.EXTRACTION_KEY_COUNTRY:
          const issuingCountry = this.getIssuingCountry(data.value);
          if (issuingCountry) {
            credential.countryAlpha2 = issuingCountry.alpha2?.toLowerCase();
            credential.countryName = issuingCountry.label;

            const flagIconClassName = issuingCountry.alpha2 ? 'flag flag-' + issuingCountry.alpha2.toLowerCase() : IconConstants.DEF_ICON;

            credential.country = new CodeLabelIcon(credential.countryAlpha2 ?? '', issuingCountry.label ?? '', flagIconClassName);
          }
          break;
        case WorkflowDatatableElement.EXTRACTION_KEY_TYPE:
          credential.type = data.value;

          const documentTypeIcon =
            IconConstants.DOCUMENT_TYPE_ICON_MAP[data.value as keyof typeof IconConstants.DOCUMENT_TYPE_ICON_MAP] || IconConstants.DEF_ICON;

          //@ts-ignore
          credential.typeCodeLabel = new CodeLabelIcon(data.value, data.value, documentTypeIcon);

          break;
        case WorkflowDatatableElement.EXTRACTION_KEY_FIRST_NAME:
          credential.firstName = data.value;
          break;
        case WorkflowDatatableElement.EXTRACTION_KEY_LAST_NAME:
          credential.lastName = data.value;
          break;
      }
    }

    credential.channel = credentialSummary.channel;

    return credential;
  }

  // FIXME: this function can be called by undefined too, because the data.value can be undefined too in the getExtractionData function
  public getIssuingCountry(countryString: string | undefined): Country | undefined {
    try {
      //@ts-ignore
      return JSON.parse(countryString) as Country;
    } catch (e) {
      console.error('ERROR while parsing issuingCountry', e);
      return;
    }
  }
}

/**
 * Used only on the UI to represent the required struct
 */
export interface WorkflowDatatableCredential {
  result: string;
  capability: string;
  credential: string;
  channel?: string | undefined;
  type?: string | undefined;
  typeCodeLabel?: CodeLabelIcon | undefined;
  countryAlpha2?: string;
  countryName?: string;
  country?: CodeLabelIcon | undefined;
  firstName?: string;
  lastName?: string;
}
