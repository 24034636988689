import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, inject, signal} from '@angular/core';
import {CustomerEnvironmentData, UntilDestroy} from '@jumio/portals.core';
import {getAssetsUrl} from 'core/helpers';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {SharedPublicModule} from 'public-shared/shared-public.module';
import {Subscription} from 'rxjs';
import {getKyxIconUrl} from 'shared/components/kyx-icon.helper';
import {IconConstants} from 'shared/services/icon.constants';

@UntilDestroy()
@Component({
  selector: 'app-loading-overlay',
  standalone: true,
  templateUrl: './mfa-spinner.component.html',
  styleUrls: ['./mfa-spinner.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SharedPublicModule]
})
export class LoadingOverlayComponent {
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();

  public readonly subscription = new Subscription();

  protected readonly Icons = IconConstants.ICONS;
  protected showOverlay = signal(false);

  protected environmentProvider = inject(EnvironmentProvider<CustomerEnvironmentData>);
  protected cd = inject(ChangeDetectorRef);

  public show(): void {
    this.showOverlay.set(true);
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  public hide(): void {
    this.showOverlay.set(false);
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  protected onCancel(): void {
    this.cancel$.emit();
  }

  protected iconUrl(name: string): string {
    const iconUrl = getKyxIconUrl(name);
    return getAssetsUrl(iconUrl, this.environmentProvider.environment.deployUrl, this.environmentProvider.environment);
  }
}
