import {Component, ComponentRef, EnvironmentInjector, EventEmitter, Injector, Output, createComponent} from '@angular/core';
import {DatadogRumLoggerService} from '@jumio/datadog-rum';
import {DatadogEventType, UntilDestroy, getCurrentUser} from '@jumio/portals.core';
import {ModalComponent} from '@jumio/portals.core-ui';
import {MFA_CODE, MFA_ERROR, MFA_ERROR_MESSAGE} from 'libs/portals/core/src/lib/constants/mfa-confirmation.constants';
import {ToastrNotificationService} from 'public-shared/services/toastr/toastr.service';
import {Subscription, take} from 'rxjs';
import {LoadingOverlayComponent} from '../mfa-spinner/mfa-spinner.component';

@UntilDestroy()
@Component({
  selector: 'app-modal-mfa-confirm',
  templateUrl: './modal-mfa-confirm.component.html',
  styleUrls: ['./modal-mfa-confirm.component.less'],
  standalone: true,
  imports: [ModalComponent]
})
export class ModalMfaConfirmComponent extends ModalComponent {
  public subscription = new Subscription();

  private overlayRef: ComponentRef<LoadingOverlayComponent> | undefined;

  constructor(
    private injector: Injector,
    private notificationService: ToastrNotificationService,
    protected datadogService: DatadogRumLoggerService
  ) {
    super();
    this.resetMfaLocalStorage();
  }

  /**
   * Emits a new event whenever the user pressed the "confirm" button.
   * @type {EventEmitter<any>}
   */
  @Output() public confirm: EventEmitter<unknown> = new EventEmitter<unknown>();

  /**
   * Emits a new event whenever the user pressed the "cancel" button.
   * @type {EventEmitter<any>}
   */
  @Output() public cancel: EventEmitter<unknown> = new EventEmitter<unknown>();

  /**
   * Emits a new event whenever the user pressed the "overlayHidden" button.
   * @type {EventEmitter<any>}
   */
  @Output() public overlayHidden: EventEmitter<unknown> = new EventEmitter<unknown>();

  /**
   * Handles confirmation events. Hides the modal window and emits a new event to the outer component.
   * @param event
   */
  public async onConfirmClicked(event: Event): Promise<void> {
    this.modal?.hide();

    const {mfaConfirmationEnabled, mfaConfirmationUri} = getCurrentUser();
    if (mfaConfirmationEnabled && mfaConfirmationUri) {
      event.stopPropagation();
      event.preventDefault();

      try {
        this.showOverlay();

        window.open(mfaConfirmationUri, '_blank', 'noopener,noreferrer');

        await this.waitForLocalStorageValue();

        this.hideOverlay();
        this.confirm.emit(event);
      } catch (e) {
        // Error exists only if the MFA flow failed
        // Error does not exist if the user clicks Overlay cancel button
        if (e) {
          const error =
            localStorage.getItem(MFA_ERROR) ||
            'MFA Authentication Error' + ' | ' + localStorage.getItem(MFA_ERROR_MESSAGE) ||
            'Error updating settings';

          this.notificationService.showError(error);

          this.datadogService.logAction(DatadogEventType.MFA_SETTING_UPDATE_FAILURE, {
            meta: {
              error
            }
          });
        }

        this.hideOverlay();
        this.resetMfaLocalStorage();
      }
    } else {
      this.confirm.emit(event);
    }
  }

  /**
   * Handles cancellation events. Hides the modal window and emits a new event to the outer component.
   * @param event
   */
  public onCancelClicked(_event: any): void {
    this.modal?.hide();
    this.cancel.emit();
  }

  private showOverlay(): void {
    if (!this.overlayRef) {
      this.overlayRef = createComponent(LoadingOverlayComponent, {
        environmentInjector: this.injector.get(EnvironmentInjector)
      });

      document.body.appendChild(this.overlayRef.location.nativeElement);
    }

    this.overlayRef.instance.show();
  }

  private hideOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.instance.hide();
      this.onClose.emit();
    }
  }

  private waitForLocalStorageValue(): Promise<void> {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (localStorage.getItem(MFA_CODE)) {
          clearInterval(interval);
          resolve();
        } else if (localStorage.getItem(MFA_ERROR) || localStorage.getItem(MFA_ERROR_MESSAGE)) {
          clearInterval(interval);
          reject(new Error(localStorage.getItem(MFA_ERROR_MESSAGE) || 'MFA Error'));
        }
      }, 100); // Poll every 100ms for the key

      // Check if Overlay Cancel button was clicked and cancel current localstorage polling
      this.subscription.add(
        this.overlayRef?.instance.cancel$.pipe(take(1)).subscribe(() => {
          this.datadogService.logAction(DatadogEventType.MFA_SETTING_UPDATE_FAILURE, {
            meta: {
              action: 'MFA flow was cancelled by the user by clicking Overlay cancel button'
            }
          });

          this.notificationService.showError('Changes could not be saved as 2FA authentication was cancelled');

          clearInterval(interval);
          reject();
        })
      );
    });
  }

  protected resetMfaLocalStorage(): void {
    localStorage.removeItem(MFA_ERROR);
    localStorage.removeItem(MFA_ERROR_MESSAGE);
    localStorage.removeItem(MFA_CODE);
  }
}
