import {Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * The abstract parent of all FormGroup components.
 * Contains the inputs and outputs that all FormGroups contain.
 */
@Component({
  template: ''
})
export abstract class AbstractFormGroupComponent {
  /**
   * The label of the FormGroup that will be shown beside the input.
   */
  @Input() public label: string = '';

  /**
   * The name of the FormGroup that identifies it in the Angular Form.
   */
  @Input() public name: string = '';

  /**
   * If set to true, the FormGroup will be disabled.
   */
  @Input() public disabled: boolean = false;

  /**
   * If set to true, the FormGroup will be required to fill.
   * The form validator will return a 'required' error key if the input is empty
   */
  @Input() public required: boolean = false;

  /**
   * If set to true, the FormGroup is a "filter" type, which means it appears on a filter page.
   * In this case, the appearance of the FormGroup changes.
   */
  @Input() public filter: boolean = false;

  /**
   * The optional string that contains the permissions needed for the FormGroup to be enabled.
   */
  @Input('appIsEnabled') public roles: string | undefined;

  /**
   * The optional object which contains additional error messages for the FormGroup.
   * It can override default error messages as well if needed
   *   Example: If a 'pattern' error key showed a default 'Wrong format!' error message
   *   it can be overridden with {'pattern': 'Must be a number'} to be more clear
   */
  @Input() public errorMessages: {[key: string]: string} | undefined;

  @Input() public labelTooltip: string = '';

  /**
   * Emits an event whenever the FormGroup's value have been changed.
   * @type {EventEmitter<any>}
   */
  @Output() public valueChange = new EventEmitter<any>();
}
